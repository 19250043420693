import { useState, useEffect, useRef } from "react"

import { Box } from "@mui/material"

import { Interpret } from "./Interpret"

export const Pose = ({
    comment,
    createDetector,
    detector,
    detectorCreated
}) => {
    
    useEffect(() => {
        if (!detector) {
            console.log("checking detector - detectorCreated current value", detectorCreated)
            setTimeout(() => {
                if (!detectorCreated.current) {
                    createDetector()
                }
            }, 4000)
        }
    }, [])

    return(
        <Box
            sx={{
                display: "flex",
                width: "100vw",
                height: "100vh",
                justifyContent: "center"
            }}
        >
            <Interpret
                comment={comment}
            />
        </Box>
    )
}