import clothing from '../../../../assets/img/guidelines/clothing.svg';
import sun from '../../../../assets/img/guidelines/sun.svg';
import position from '../../../../assets/img/guidelines/position.svg';

export const GUIDELINES = {
    CLOTHING: {
        index: 0,
        body: "Portez des vêtements proches du corps.",
        icon: clothing
    },
    SUN: {
        index: 1,
        body: "Choisissez un endroit bien éclairé.",
        icon: sun
    },
    POSITION: {
        index: 2,
        body: "Posez de face, puis de profil.",
        icon: position
    }
}