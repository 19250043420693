import { useState, useEffect, useRef } from "react"

import { Box } from "@mui/material"

import { Calibrate } from "./Calibrate"
import { Animation } from "./Animation"

import { GoBack } from "../../../../molecules/GoBack"

const STEPS = {
    ANIMATION: "Animation",
    CALIBRATE: "Calibrate"
}

export const Inclination = ({
    startTutorial,
    createDetector,
    stepNumber,
    previousScreen
}) => {
    const [step, setStep] = useState(STEPS.ANIMATION)
    const detectorCreated = useRef(false)

    const nextStep = () => {
        console.log('Go hit');
        if (step === STEPS.ANIMATION) {
            if (stepNumber === 0) startTutorial();
        }
    }

    const previousStep = () => {
        if (step === STEPS.CALIBRATE) {
            setStep(STEPS.ANIMATION)
        } else {
            previousScreen()
        }
    }

    useEffect(() => {
        if (!detectorCreated.current) {
            createDetector()
            detectorCreated.current = true
        }
    }, [])

    return(
        <>
            <Box sx={{
                position: "absolute",
                zIndex: 9999999,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100vw",
                height: "90vh",
            }}>
                {step === STEPS.ANIMATION && <Animation nextStep={nextStep} />}
                {step === STEPS.CALIBRATE && <Calibrate startTutorial={stepNumber === 0 ? startTutorial : () => null} />}
            </Box>
            <GoBack onClick={previousStep}/>
        </>
    )
}