import { useEffect, useRef, useState } from "react"

import { Box, Typography } from "@mui/material"
import { STEPS } from "./utils/constants"

export const Interpret = ({ 
    comment
}) => {

    if (comment) {

        return(
            <>
                <Box sx={{
                    position: "absolute",
                    zIndex: 99999,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    width: '100vw',
                    height: '100vh',
                }}>
                    <Box sx={{
                        width: '90vw',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography
                            fontSize={"40px"}
                            fontWeight={"bold"}
                            fontFamily="Helvetica Neue"
                            color='white'
                            sx={{
                                width: '95%'
                            }}
                        >
                            {comment}
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    } else {
        return null;
    }
}