export function normalizeVector2d(vector) {
    const magnitude = Math.sqrt(vector[0] ** 2 + vector[1] ** 2);
    if (magnitude !== 0) {
      return [vector[0] / magnitude, vector[1] / magnitude];
    } else {
      return vector;
    }
}
export function normalizeVector3D(vector) {
    const magnitude = Math.sqrt(vector[0] ** 2 + vector[1] ** 2 + vector[2] ** 2);
    return magnitude !== 0 ? vector.map((e) => e / magnitude) : vector;
}
  
export function subtractVectors(vector1, vector2) {
    return vector1.map((e, i) => e - vector2[i]);
}
  
export function addVectors(vector1, vector2) {
    return vector1.map((e, i) => e + vector2[i]);
}
  
export function scalarProduct(vector1, vector2) {
    return vector1.map((e, i) => e * vector2[i]).reduce((a, b) => a + b);
}
  
export function multiplyVector(v, alpha) {
    return v.map((e) => e * alpha);
}

export function angleBetweenVectors(vector1, vector2) {
    let vector1_normalized = normalizeVector2d(vector1)
    let vector2_normalized = normalizeVector2d(vector2)
    return (Math.PI - Math.acos(scalarProduct(vector1_normalized, vector2_normalized))) * 180 / Math.PI
}

export function crossProduct3D(a, b) {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0],
    ];
}

export function compute2Dnorm(v) {
    if (v.length < 2) {
        throw new Error('Le vecteur doit avoir au moins deux dimensions.');
    }
    const x = v[0];
    const y = v[1];
    return Math.sqrt(x * x + y * y);
}
  
export function computeAngleLimb3parts(markers, vertical, i1, i2, i3) {
    let a = subtractVectors(markers[i2], markers[i1]);
    a = normalizeVector2d(a);
  
    let b = subtractVectors(markers[i3], markers[i1]);
    b = normalizeVector2d(b);
  
    let angle_in_limb = (Math.acos(scalarProduct(a, b)) * 180) / Math.PI;
    let angle_to_vertical = (Math.acos(-scalarProduct(vertical, b)) * 180) / Math.PI;
  
    return { angle_in_limb: angle_in_limb, angle_to_vertical: angle_to_vertical };
}

export function triangleArea(a, b, c) {
    return 1/2*Math.abs(a[0]*(b[1]-c[1]) + b[0]*(c[1]-a[1]) + c[0]*(a[1]-b[1]))
}

export function pentagonArea(a,b,c,d,e,f) {
    return triangleArea(a,b,c) + triangleArea(a,c,d) + triangleArea(a,d,e)
}