export const THRESHOLD_RATIO_CENTER = 0.15;
// Angle arm/vertical
export const ANGLE_ARMS = 25;
export const DELTA_ANGLE_ARMS = 1;
// Angle leg/vertical
export const ANGLE_LEGS = 40;
export const DELTA_ANGLE_LEGS = 10;
// Angles that should be 0 for limbs to be straights
export const THRESHOLD_ANGLE_STRAIGHT_ARM = 160;
export const THRESHOLD_ANGLE_ARM_TO_VERTICAL_LOWER = 15;
export const THRESHOLD_ANGLE_ARM_TO_VERTICAL_UPPER = 60;
export const THRESHOLD_ANGLE_STRAIGHT_LEG = 10;
// Epsilon defining 2D norm target
export const EPSILON_SHOULDER = .25
export const EPSILON_HIP = .15
// Confidence threshold for markers
export const THRESHOLD_CONFIDENCE = .4
// Area threshold to check human distance
export const THRESHOLD_AREA = .15
export const THRESHOLD_ANGLE_FRONT = .45

export const POSITIONS = {
    FRONT: "Face",
    SIDE: "Profil",
    NOWHERE: "Pas dans le cadre"
}

export const KEYPOINTS = {
    0: "nose",
    11: "left_shoulder",
    12: "right_shoulder",
    13: "left_elbow",
    14: "right_elbow",
    15: "left_wrist",
    16: "right_wrist",
    23: "left_hip",
    24: "right_hip",
    25: "left_knee",
    26: "right_knee",
    27: "left_ankle",
    28: "right_ankle"
}

export const LINES = [
    [0, 12.5],
    [11, 12.5],
    [11, 13],
    [11, 23],
    [12, 12.5],
    [12, 14],
    [12, 24],
    [13, 15],
    [14, 16],
    [23, 25],
    [23, 24],
    [23.5, 12.5],
    [23.5, 26],
    [23.5, 27],
    [25, 27],
    [24, 26],
    [26, 28]
]

export const STEPS = {
    FRONT: "Front",
    SIDE: "Side"
}