import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { Welcome } from "./Welcome"
import { Camera } from "./Camera";
import { Guidelines } from "./Guidelines";
import { Error } from "./Error";

import { API_ENDPOINT } from "../../api/constants";

const STEPS = {
  WELCOME: "Welcome",
  GUIDELINES: "Guidelines",
  CAMERA: "Camera",
  ERROR: "Error"
}

export const Scenarii = () => {
  const [step, setStep] = useState(STEPS.GUIDELINES)
  const [searchParams, setSearchParams] = useSearchParams()

  const scanPushAnswers = async (answers) => {
    console.log('scanPushAnswers inputs', answers)
    const mid = searchParams.get("mid") || null;
    if (mid) {
      const success = await fetch(`${API_ENDPOINT}scan-push-answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          measure_id: mid,
          answers: answers
        })
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log('Error: scanPushAnswers - ', e);
          return false;
        });
      console.log('scanPushAnswers success', success)
      return success;
    } else {
      return false;
    }
  };

  const createPUTlinks = () => {
    const answers = {
      age: Number(searchParams.get("age")) || 40,
      weight: Number(searchParams.get("weight")) || 70,
      height: Number(searchParams.get("height")) || 170,
      gender: searchParams.get("gender") || 'male',
    }
    const result = scanPushAnswers(answers).then((links) => {
      console.log('scanPushAnswers links', links)
      if (typeof links?.error !== 'string') {
        localStorage.setItem('front_put_url', links?.front_put_url)
        localStorage.setItem('side_put_url', links?.side_put_url)
      }
    })
  };

  const restart = () => {
    setStep(STEPS.GUIDELINES);
    createPUTlinks();
  }

  useEffect(() => {
    createPUTlinks();
  }, [])

  const nextStep = () => {
    if (step === STEPS.GUIDELINES) {
      setStep(STEPS.CAMERA)
    }
  }

  const previousStep = () => {
    if (step === STEPS.CAMERA) {
      setStep(STEPS.GUIDELINES)
    }
  }

  return (
    <Box>
      {step === STEPS.GUIDELINES && (
        <Guidelines 
          nextStep={nextStep}
        />
      )}
      {step === STEPS.CAMERA && (
        <Camera 
          closeCamera={nextStep}
          previousScreen={previousStep}
          restart={restart}
        />
      )}
      {step === STEPS.ERROR && (
        <Error
          retry={restart}
        />
      )}
    </Box>
  );
};
