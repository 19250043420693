import { 
    THRESHOLD_RATIO_CENTER,
    ANGLE_ARMS,
    DELTA_ANGLE_ARMS,
    ANGLE_LEGS,
    DELTA_ANGLE_LEGS,
    THRESHOLD_ANGLE_STRAIGHT_ARM,
    THRESHOLD_ANGLE_ARM_TO_VERTICAL_LOWER,
    THRESHOLD_ANGLE_ARM_TO_VERTICAL_UPPER,
    THRESHOLD_ANGLE_STRAIGHT_LEG,
    THRESHOLD_ANGLE_FRONT,
    EPSILON_SHOULDER,
    EPSILON_HIP,
    THRESHOLD_CONFIDENCE,
    POSITIONS,
    STEPS
} from "./constants"

import {
    addVectors,
    multiplyVector,
    normalizeVector3D,
    crossProduct3D,
    subtractVectors,
    scalarProduct,
    compute2Dnorm,
    normalizeVector2d,
    computeAngleLimb3parts,
    pentagonArea,
    angleBetweenVectors
} from "./math"

function checkArmAndLegs(
    markers,
    mark_shoulder_right,
    mark_shoulder_left,
    mark_hip_right,
    mark_hip_left,
    mark_knee_right,
    mark_knee_left,
    mark_ankle_right,
    mark_ankle_left,
) {
    let vertical = normalizeVector2d(
        addVectors(
            subtractVectors(mark_shoulder_right, mark_hip_right),
            subtractVectors(mark_shoulder_left, mark_hip_left)
        )
    );
    
    let members_results = {
        arm: {
            left: computeAngleLimb3parts(markers, vertical, 12, 14, 16), // to replace by actual marks
            right: computeAngleLimb3parts(markers, vertical, 11, 13, 15), // to replace by actual marks
        },
        leg: {
            left: computeAngleLimb3parts(markers, vertical, 24, 26, 28), // to replace by actual marks
            right: computeAngleLimb3parts(markers, vertical, 23, 25, 27), // to replace by actual marks
        },
    };
    
    // Check straightness of arms
    for (const side of ["right", "left"]) {
        if (members_results.arm[side].angle_in_limb > THRESHOLD_ANGLE_STRAIGHT_ARM) {
            console.log(`Straighten your ${side} arms`);
        }
    } 
    
    if (
        members_results.arm.right.angle_to_vertical +
        members_results.arm.left.angle_to_vertical >
        2 * (ANGLE_ARMS + DELTA_ANGLE_ARMS)
    ) {
        console.log("Lower the arms a bit");
    } else if (
        members_results.arm.right.angle_to_vertical +
        members_results.arm.left.angle_to_vertical <
        2 * (ANGLE_ARMS - DELTA_ANGLE_ARMS)
    ) {
        console.log("Lift the arms a bit");
    }

    // Legs
    for (const side of ["right", "left"]) {
        if (members_results.leg[side].angle_in_limb > THRESHOLD_ANGLE_STRAIGHT_LEG) {
            console.log(`Straighten your ${side} leg`);
        }
    } // Check straightness of legs
    
    if (
        members_results.arm.right.angle_to_vertical +
        members_results.arm.left.angle_to_vertical >
        2 * (ANGLE_LEGS + DELTA_ANGLE_LEGS)
    
    ) {
        console.log("Close the legs a bit");
    } else if (
        members_results.arm.right.angle_to_vertical +
        members_results.arm.left.angle_to_vertical <
        2 * (ANGLE_LEGS - DELTA_ANGLE_LEGS)
    ) {
        console.log("Open the legs a bit");
    }
}

// Check where the body is relative to the center
function checkHumanIsCentered(markers, img_width) {
    let x_center_body =
    [11, 11, 23, 24].map((i) => markers[i][0]).reduce((a, b) => a + b) / 4;
    x_center_body = x_center_body / img_width - 0.5;
    
    // Check where the body is relative to the center
    
    if (x_center_body > THRESHOLD_RATIO_CENTER) {
        //console.log("Please move a bit on your right");
    } else if (x_center_body < -THRESHOLD_RATIO_CENTER) {
        //console.log("Please move a bit on your left");
    }
} 

function checkHumanOrientation(markers) {
    // Compute pointing_vector of torso
    let middle_23_24 = multiplyVector(addVectors(markers[23], markers[24]), 0.5);
    
    let pointing_vec = normalizeVector3D(
    crossProduct3D(
        subtractVectors(markers[11], middle_23_24),
        subtractVectors(markers[12], middle_23_24)
    )
    );
    
    // Check orientation of body
    let angle_to_cam = (Math.PI / 2 - Math.acos(scalarProduct([1, 0, 0], pointing_vec))) * 180 / Math.PI;
    //console.log("ANGLE", pointing_vec)
    if (angle_to_cam > 20) {
        //console.log("Please face towards the camera");
    }

    let shoulder_norm = compute2Dnorm(subtractVectors(normalizeVector2d(markers[13]), normalizeVector2d(markers[14])))
    let hip_norm = compute2Dnorm(subtractVectors(normalizeVector2d(markers[23]), normalizeVector2d(markers[24])))

    if (shoulder_norm && hip_norm) {
        if (shoulder_norm < EPSILON_SHOULDER && hip_norm < EPSILON_HIP) {
            return POSITIONS.SIDE
        }
        if (shoulder_norm > EPSILON_SHOULDER && hip_norm > EPSILON_HIP) {
            return POSITIONS.FRONT
        }
    } else {
        return null;
    }
}

function checkMarkersAreVisible(markers, sublist) {
    const result = {};
    for (const index of sublist) {
        let element = markers[index];
        result[index] = {decision: true, reason: null};
        if (
            element.score < THRESHOLD_CONFIDENCE
        ) {
            result[index].decision = false;
            if (element.score < THRESHOLD_CONFIDENCE) {
                result[index].reason = Math.round(10*element.score)/10
            } else {
                result[index].reason = 'out'
            }
        }
    }
    return result;
}

function checkArmLinearity(mark_shoulder, mark_elbow, mark_wrist) {
    const vectorShoulderToElbow = subtractVectors([mark_shoulder.x, mark_shoulder.y], [mark_elbow.x, mark_elbow.y])
    const vectorElbowToWrist = subtractVectors([mark_elbow.x, mark_elbow.y], [mark_wrist.x, mark_wrist.y])

    const angle = angleBetweenVectors(vectorShoulderToElbow, vectorElbowToWrist)
    if (angle > THRESHOLD_ANGLE_STRAIGHT_ARM) {
        return true;
    } else {
        return false;
    }
}

function checkArmAngle(mark_shoulder, mark_wrist, mark_hip_left, mark_hip_right, mark_nose) {
    let mark_hip_middle = addVectors(
        [0.5*mark_hip_left.x, 0.5*mark_hip_left.y], 
        [0.5*mark_hip_right.x, 0.5*mark_hip_right.y]
    )
    const vertical = subtractVectors(mark_hip_middle, [mark_nose.x, mark_nose.y])
    const arm = subtractVectors([mark_shoulder.x, mark_shoulder.y], [mark_wrist.x, mark_wrist.y])
    
    const angle = angleBetweenVectors(arm, vertical)
    if (angle < THRESHOLD_ANGLE_ARM_TO_VERTICAL_LOWER) {
        return [-1, angle]
    } else if (angle > THRESHOLD_ANGLE_ARM_TO_VERTICAL_UPPER) {
        return [1, angle]
    } else {
        return [0, angle]
    }
}


function checkAngleToCamera(
    mark_nose,
    mark_hip_left,
    mark_hip_right,
    mark_shoulder_left,
    mark_shoulder_right,
    step
) {
    let mark_hip_middle = addVectors(
        [0.5*mark_hip_left.x, 0.5*mark_hip_left.y], 
        [0.5*mark_hip_right.x, 0.5*mark_hip_right.y]
    )

    let vertical = subtractVectors(mark_hip_middle, [mark_nose.x, mark_nose.y])
    const vertical_dist = compute2Dnorm(vertical)

    let horizontal = subtractVectors(
        [mark_shoulder_left.x, mark_shoulder_left.y],
        [mark_shoulder_right.x, mark_shoulder_right.y]
    )
    let horizontal_dist = compute2Dnorm(horizontal)

    if (step === "FRONT") {
        if (horizontal_dist/vertical_dist < THRESHOLD_ANGLE_FRONT) { return false }
        else { return true }
    } else if (step === "SIDE") {
        if (horizontal_dist/vertical_dist > THRESHOLD_ANGLE_FRONT) { return false }
        else { return true }
    }
    
}

export function checkFront(markers, img_width, img_height) {
    
    const sublist = [0, 5, 6, 9, 10, 11, 12]
    const visibilityRes = checkMarkersAreVisible(markers, sublist, img_width, img_height)
    const visibility = Object.values(visibilityRes).every(value => value.decision === true)
    if (!visibility) { 
        const missingFront = Object.entries(visibilityRes).reduce((acc, [key, value]) => {
            if (value.decision === false) {
                acc[key] = value.reason;
            }
            return acc;
        }, {});
        console.log('missingFront', missingFront);
        const resultString = Object.entries(missingFront)
            .map(([key, value]) => `${key} ${value}`)
            .join(' ');
        console.log('resultString', resultString);
        return `POSEZ DE FACE` 
    }

    let mark_nose = markers[0]
    let mark_shoulder_left = markers[5]
    let mark_shoulder_right = markers[6]
    let mark_wrist_left = markers[9]
    let mark_wrist_right = markers[10]
    let mark_hip_left = markers[11]
    let mark_hip_right = markers[12]
    if (!(mark_nose && mark_shoulder_left && mark_shoulder_right && mark_wrist_left && mark_wrist_right && mark_hip_left && mark_hip_right)) {
        return "POSEZ DE FACE"
    }
    console.log('markers', markers)
    console.log('mark_hip_left', mark_hip_left)
    const [isLeftArmCorrectlyPlaced, angleLeft] = checkArmAngle(mark_shoulder_left, mark_wrist_left, mark_hip_left, mark_hip_right, mark_nose)
    const [isRightArmCorrectlyPlaced, angleRight] = checkArmAngle(mark_shoulder_right, mark_wrist_right, mark_hip_left, mark_hip_right, mark_nose)
    if (isLeftArmCorrectlyPlaced === 1 || isRightArmCorrectlyPlaced === 1) {
        return 'RESSEREZ VOS BRAS'
    } else if (isLeftArmCorrectlyPlaced === -1 || isRightArmCorrectlyPlaced === -1) {
        return 'ÉCARTEZ VOS BRAS'
    }

    const ratioShoulderHip = checkAngleToCamera(mark_nose, mark_hip_left, mark_hip_right, mark_shoulder_left, mark_shoulder_right, "FRONT")
    if (!ratioShoulderHip) { return "POSEZ DE FACE" }

    return true
}

export function checkSide(markers, img_width, img_height) {

    const sublist_left = [0, 5, 11, 13]
    const sublist_right = [0, 6, 12, 14]
    const visibility_left_res = checkMarkersAreVisible(markers, sublist_left, img_width, img_height)
    const visibility_left = Object.values(visibility_left_res).every(value => value.decision === true)
    const visibility_right_res = checkMarkersAreVisible(markers, sublist_right, img_width, img_height)
    const visibility_right = Object.values(visibility_right_res).every(value => value.decision === true)
    if (!visibility_left && !visibility_right) { return "POSEZ DE PROFIL" }

    let mark_nose = markers[0]
    let mark_shoulder_left = markers[5]
    let mark_shoulder_right = markers[6]
    let mark_elbow_left = markers[13]
    let mark_elbow_right = markers[14]
    let mark_wrist_left = markers[15]
    let mark_wrist_right = markers[16]
    let mark_hip_left = markers[11]
    let mark_hip_right = markers[12]  
    if (!(mark_nose && mark_shoulder_left && mark_shoulder_right && mark_hip_left && mark_hip_right)) { return "POSEZ DE PROFIL" }

    // let isArmStraight = false
    // if (visibility_left) {
    //     isArmStraight = checkArmLinearity(mark_shoulder_left, mark_elbow_left, mark_wrist_left)
    // } else if (visibility_right) {
    //     isArmStraight = checkArmLinearity(mark_shoulder_right, mark_elbow_right, mark_wrist_right)
    // }
    // if (!isArmStraight) { return "Gardez votre bras le long du corps" }

    
    const ratioShoulderHip = checkAngleToCamera(mark_nose, mark_hip_left, mark_hip_right, mark_shoulder_left, mark_shoulder_right, "SIDE")
    if (!ratioShoulderHip) { return "POSEZ DE PROFIL" }

    return true;
}
export function check(markers, step, img_width, img_height) { 
    if (step === STEPS.FRONT) { return checkFront(markers, img_width, img_height) }
    else if (step === STEPS.SIDE) { return checkSide(markers, img_width, img_height) }
    return null;
}