import { Button, Typography } from "@mui/material";

import { outcome } from "./utils";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import './result.css';

export const Outcome = ({ type, restart }) =>  {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMobile = searchParams.get('isMobile') || false;

    useEffect(() => {
        if (isMobile && type === 'success') {
            setTimeout(() => window.close(), 2000)
        }
    }, [type])

    return(
        <>
            <Typography
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Helvetica Neue"}
            >
                {outcome[type].title}
            </Typography>
            <Typography
                fontSize={15}
                fontFamily={"Helvetica Neue"}
                fontWeight={"regular"}
                mx="30px"
                my="20px"
            >
                {outcome[type].message}
            </Typography>
            {type !== 'success' && (
                <button 
                    onClick={restart}
                    className="retry"
                >
                    Recommencer
                </button>
            )}

        </>
    )
}